import React, {useState, useEffect} from 'react'
import styles from './Header.module.scss';
import { Link, navigate } from 'gatsby';

const Header = () => {

    const [showMenu, setterShowMenu] = useState(false);

    useEffect(() => {
        console.log("%c  *    .  *       .             *        " + '\n' + 
        '                         *        ' + '\n' + 
        ' *   .        *       .       .       *        ' + '\n' + 
        '   .     *        ' + '\n' + 
        '    https://twitter.com/stassonmars         ' + '\n' +
        '       .                .        .        ' + '\n' +
        '.  *           *                     *        ' + '\n' +
        '                             .        ' + '\n' +
        '         *          .   *        ', 'color:aqua; font-weight:bold'   )
    }, []);

    return (
        <>
        <div className={showMenu ? `${styles.mobile__menu} ${styles.mobile__menu__show}` : `${styles.mobile__menu} `}>
                <a href="#" className={styles.close__mob} aria-label="Закрыть мобильное меню" onClick={() => setterShowMenu(false)}>×</a>
                <Link className={styles.category__item} to='/javascript'>JS</Link>
                <Link className={styles.category__item} to='/html-css'>HTML/CSS</Link>
        </div>
        <header className={styles.header__main}>
            <img onClick={() => navigate('/')} src="/../logo.png" alt="" width="400" height="90" className={styles.main_logo}/>
            <nav>
                <Link to='/javascript'>JS</Link>
                <Link to='/html-css'>HTML/CSS</Link>
            </nav>
            <img onClick={() => setterShowMenu(true)} className={styles.shawa} src="/../shaurma.png" alt="" width="30" height="70" />
            
            <a className={styles.tw} href="https://twitter.com/stassonmars">
                <img src="/../twitt.png" alt="" width="25" height="25" />
            Подписывайтесь на мой твиттер, там всегда что-нибудь интересное!</a>
        </header>
        </>
    )
};

export default Header;