/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, keywords, schema, img_smm, initPubdate, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const keywordsData = keywords ? {name: `keywords`, content: keywords} : {}
  const imageSmm = img_smm ? { property: `og:image`, content: img_smm} : {}
  const twtrImg = img_smm ? { name: `twitter:image`, content: img_smm} : {}
  const initDate = initPubdate ? {property: `DC.date.issued`, content: initPubdate} : {}
  const twtUrl = url ? { name: 'twitter:url', content: url} : {}

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s `}
      meta={[
        {
          name: "google-site-verification",
          content: "wDRfrQ8rGOL7rZaNSksNTeFNux97ohqaKtWHDtsxNO0"
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: url,
        },
        imageSmm,
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: '@stassonmars',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        twtrImg,
        twtUrl,
        initDate,
        {
          name: `robots`,
          content: `index, follow`
        },
        keywordsData
      ].concat(meta)}> {schema && <script type='application/ld-json'>{JSON.stringify(schema)}</script>} </Helmet>
  )
}

SEO.defaultProps = {
  lang: `ru`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO